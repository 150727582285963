import React from "react";

const Product = ({ productDetail }) => {
  const { image, name, sku, bom_details, breakup, discount } =
    productDetail;
  return (
    <div className="search_product_list">
      <div className="search_product_image">
        <div className={"search_product_price_group"}>
          <h3>{name}</h3>
          <span className={"search_product_sku"}>{sku}</span>
        </div>
        <img src={image} alt={name} />
      </div>
      <div className="search_product_content">
        <div className="search_product_content_inner">
          <h3 className="search_product_title">Specifications</h3>
          <ul className="search_product_ul">
            {Object.keys(bom_details).length > 0 &&
              Object.keys(bom_details).map((category) => {
                return (
                  category !== "totals" &&
                  bom_details[category].map((item, index) => (
                    <li className="search_product_item" key={index}>
                      <div className="sr_lst_label">
                        {item.label}
                        {item.count ? `(${item.count} Pcs.)` : ""}
                      </div>
                      <div className="sr_lst_span">{item.weight}</div>
                    </li>
                  ))
                );
              })}
          </ul>
        </div>
        <div className="search_product_content_inner">
          <h3 className="search_product_title">Price Breakup</h3>
          <ul className="search_product_ul">
            {breakup.metal.map((metal, metalIndex) => (
              <li className="search_product_item" key={metalIndex}>
                <div className="sr_lst_label">{metal.label}</div>
                <div className="sr_lst_span">₹{metal.value}</div>
              </li>
            ))}
            <li className="search_product_item">
              <div className="sr_lst_label">
                {breakup.diamond.diamond_label}
              </div>
              <div className="sr_lst_span">
                ₹{breakup.diamond.actual_diamond_cost}
              </div>
            </li>
            <li className="search_product_item">
              <div className="sr_lst_label">{breakup?.making.label}</div>
              <div className="sr_lst_span">₹{breakup?.making.actual_cost}</div>
            </li>
            {discount ? (
              <li className="search_product_item">
                <div className="sr_lst_label">Discount</div>
                <div className="sr_lst_span">
                  - ₹{discount}
                </div>
              </li>
            ):''}
            <li className="search_product_item">
              <div className="sr_lst_label">Tax</div>
              <div className="sr_lst_span">
                ₹{breakup.total_tax}
              </div>
            </li>
            <li className="search_product_item total_item">
              <div className="sr_lst_label">Total</div>
              <div className="sr_lst_span">
                {breakup.discounted_final_cost && (
                  <del>₹{breakup.final_cost}</del>
                )}
                ₹{breakup.discounted_final_cost || breakup.final_cost}
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Product;
