import { Route, Routes } from "react-router-dom";
import "./App.css";
import ProductDetail from "./ProductDetail/ProductDetail";

const App = () => {
  return (
    <Routes>
          <Route path="/" element={<ProductDetail />} />
    </Routes>
  );
};

export default App;
