import { useCallback, useEffect, useRef, useState } from "react";
import Product from "./Product";
import { useNavigate, useSearchParams } from "react-router-dom";

const ProductDetail = () => {
  const [inputValue, setInputValue] = useState("");
  const [productDetail, setProductDetail] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [searchParams, ] = useSearchParams();
  let lastBarcode = useRef('');

  const getSearchData = useCallback(
    async (barcode) => {
      if (barcode && lastBarcode.current !== barcode) {
        lastBarcode.current = barcode;
        try {
          setLoading(true);
          const response = await fetch(
            `/search?barcode=${barcode}`
          );
          setLoading(false);

          if (!response.ok) {
            console.log(new Error(`HTTP error! Status: ${response.status}`));
          }
          const data = await response.json();
          // console.log(data,'data');
          if (data.success === false) {
            setProductDetail({});
          } else {
            setProductDetail(data);
          }
          navigate(`/?barcode=${barcode}`);
        } catch (e) {
          console.log(e, "error while tooltip load");
        }
      }
    },
    [setProductDetail, navigate, lastBarcode]
  );

  useEffect(() => {
    const barcode = searchParams.get("barcode");
    setInputValue(barcode ? barcode : "");
    getSearchData(barcode);
  }, [setInputValue, getSearchData, searchParams]);

  return (
    <main className="main_search">
      <div className="search_container">
        <div className="search_group">
          <input
            type="text"
            name="search"
            value={inputValue}
            onChange={(e) => {
              setInputValue(e.target.value);
            }}
            placeholder="Search..."
          />
          {inputValue && (
            <button
              className="cm_btn cm_btn_cancel"
              onClick={() => setInputValue("")}
            >
              ×
            </button>
          )}
        </div>
        <button
          type="button"
          className="cm_btn cm_btn_search"
          disabled={!inputValue || loading}
          onClick={() => getSearchData(inputValue)}
        >
          {loading ? 'Loading...' : 'Search'}
        </button>
      </div>

      {Object.keys(productDetail).length > 0 ? (
        <Product productDetail={productDetail} />
      ):
      <div className="error_text">
        {lastBarcode.current ?
          <h4>Barcode not found!</h4>
        :
          <h4>Type barcode and hit Search.</h4>
        }
      </div>
    }
    </main>
  );
};

export default ProductDetail;
